

















import { Component, Vue, Ref } from 'vue-property-decorator'
import WeekForm from '../components/weekForm/index.vue'
import { getWeekPlanInfo, edidWeekPlan } from '@/api/week'

const defaultForm = {}

@Component({
  components: {
    WeekForm,
  },
  beforeRouteLeave(this: any, to: any, from: any, next: any) {
    if (this.isChange()) {
      this.$confirm({
        cancelText: '放弃',
        okText: '继续编辑',
        content: '系统检测到您还未提交，确定放弃已编辑的内容吗？',
        onCancel: () => {
          this.showPage = false
          this.$nextTick(() => {
            next()
          })
        },
        onOk: () => {
          next(false)
        },
      })
    } else {
      this.showPage = false
      this.$nextTick(() => {
        next()
      })
    }
  },
})
export default class editWeeklyPlan extends Vue {
  @Ref('weekFormRef')
  private weekFormRef!: any

  formData: any = { ...defaultForm }
  private submitLoading = false

  isChange() {
    const formData = this.weekFormRef.getFormData()
    // formData没值，放行
    if (!formData) return false
    const {
      thisWeekContent: oldThisWeekContent,
      nextWeekContent: oldNextWeekContent,
      helpContent: oldHelpContent,
    } = this.formData
    const { thisWeekContent, nextWeekContent, helpContent } = formData
    // 只要一个有值改变并且没有点击提交，拦截
    return (
      (thisWeekContent !== oldThisWeekContent ||
        nextWeekContent !== oldNextWeekContent ||
        helpContent !== oldHelpContent) &&
      !this.submitted
    )
  }
  private showPage = true
  cancel() {
    this.$router.push('/weekPlan/index')
  }
  private submitted: boolean = false
  async submitData() {
    const formData = this.weekFormRef.getFormData()
    if (!formData) return
    this.submitLoading = true
    const [err] = await edidWeekPlan(formData)
    this.submitLoading = false
    if (err) return
    this.submitted = true
    this.$message.success('编辑成功')
    this.cancel()
  }
  async getDetailById() {
    const { id } = this.$route.params
    const [err, res] = await getWeekPlanInfo(id as string)
    if (err) return
    this.formData = { ...res }
    this.weekFormRef.setFormData(res)
  }
  created() {
    this.getDetailById()
  }
}
