import { GET, POST, DELETE, PUT } from '@/config/fetch'

// 获取周计划
export const getWeekPlanList = (params: any) =>
  GET('/front/dailylog/v1/weekPlan/page', params)

// 获取周计划详情
export const getWeekPlanInfo = (id: string) =>
  GET(`/front/dailylog/v1/weekPlan/${id}`)

// 添加周计划
export const addWeekPlan = (params: any) =>
  POST('/front/dailylog/v1/weekPlan', params)

// 编辑周计划
export const edidWeekPlan = (params: any) =>
  PUT(`/front/dailylog/v1/weekPlan/${params.id}`, params)

// 日计划导出
export const exportWeekPlan = (params: any) =>
  GET(
    `/front/dailylog/v1/weekPlan/exportExcel`,
    { ...params, fileName: '周计划' },
    {
      responseType: 'blob',
    },
  )
