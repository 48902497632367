









































import { Component, Vue } from 'vue-property-decorator'
import MyTinymce from '@/components/Editor/index.vue'
import Share from '@/components/Share/index.vue'
@Component({
  components: {
    MyTinymce,
    Share
  },
})
export default class WeekForm extends Vue {
  private formData: any = {
    thisWeekContent: '',
    nextWeekContent: '',
    helpContent: '',
    shareList:[]
  }
  currentPlanId:any = ''
  setFormData(data: any) {
    this.formData = { ...data }
  }
  getFormData() {
    return this.formData
  }
  addShareList(e:any){
    this.formData.shareList = e
  }
}
